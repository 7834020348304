<template>
  <section>
    <div v-if="loading" class="loadingBox">
      <v-progress-circular
        indeterminate
        size="100"
        color="blue"
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <h2 class="mb-3">Gig Agreement</h2>
      <div>
        <h3>Terms and Condition for Gig</h3>
        <p>
          Gigs can be related to your daily lifestyle, freelance job or a way to
          utilize your planned things to do. Use gig wisely and get monetary
          freedom. These terms and conditions govern the use of services made
          available through Postaam.com or Postaam App for all gigs. Please go
          through following terms:
        </p>
        <ul class="listPoints">
          <li>
            Content creator must have access to Affiliation feature. (Haven’t
            applied yet? Please apply for affiliation “here”.
          </li>
          <li>Applicant must be 16 years or above.</li>
          <li>
            Content Creators should always follow age guideline before accepting
            the gigs.
          </li>
          <li>
            Any or all contents submitted must follow provided guideline, or the
            submitted content will not be approved for applicable payment.
          </li>
          <li>
            Creator must submit content before provided time limit expires. If
            time exceeds gig will be assigned to another creator
          </li>
          <li>
            Unsuccessful submission of gig content multiple time within time
            limit can result in suspension or ban to engage in GIGS.
          </li>
          <li>
            You will have chance to revise your content if any mistake is found
            for limit set only.
          </li>
          <li>
            All approved contents will be posted in both creator and business
            profile. Business will have right to share the content in their
            social handles or website for advertisement and promotional use
            case.
          </li>
          <li>
            Approved contents cannot be deleted for minimum of 3 month period.
            So, apply to gigs you are comfortable with only.
          </li>
          <li>
            Make sure you have permission of people/business/place involved in
            content, before submitting content. Any legal issue relating to it
            will not be entertained by Postaam Infosys Pvt. Ltd.
          </li>
        </ul>

        <h3>Guide:</h3>
        <ul class="listPoints">
          <li>
            Setup your profile before applying with your creative contents.
          </li>
          <li>
            Share your profile and increase engagement, for high paying gigs.
          </li>
          <li>Always complete gigs before timeline to avoid suspensions.</li>
          <li>
            You will also be entitled to revenue from any sales received from
            the affiliated post submitted via Postaam Gigs.
          </li>
          <li>
            Review business issuing gigs, to understand business nature and
            product or service offered.
          </li>
          <li>
            Always create multiple content so that you can use it if your
            submitted content comes back for revision.
          </li>
        </ul>

        <ul class="buttonList">
          <li><v-btn @click="acceptAct">Accept</v-btn></li>
          <li><v-btn @click="rejectAct">Reject</v-btn></li>
        </ul>
      </div>
    </div>
    <div v-if="backModal" class="modalBackground">
      <div class="modalBox">
        <h2>Are you sure you want to go back?</h2>
        <p>
          You will not be able to apply for gigs until you accept the terms and
          conditions.
        </p>
        <v-btn @click="goBack">Yes</v-btn>
        <v-btn @click="backModal = false">No</v-btn>
      </div>
    </div>
    <v-snackbar
      v-model="snackbar"
      color="red"
    >
      {{ this.allGigTermsAccept.message }}

     
    </v-snackbar>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Gig",
  data: () => ({
    status: false,
    loading: true,
    backModal: false,
    snackbar: false,
  }),
  methods: {
    ...mapActions(["getGigTermsAccept", "postGigTermsAccept"]),
    async acceptAct() {
      await this.postGigTermsAccept();
      this.status = this.allGigTermsAccept.results;
      this.snackbar = true;
      if (this.status) {
        this.$router.push("/giglist");
      }
    },
    goBack() {
      this.$router.push("/gigback");
    },
    rejectAct() {
      this.backModal = true;
    },
  },
  computed: {
    ...mapGetters(["allGigTermsAccept"]),
  },
  async created() {
    await this.getGigTermsAccept();
    this.status = this.allGigTermsAccept.results;
    if (this.status) {
      this.$router.push("/giglist");
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  .modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    width: 400px;
    text-align: center;
    h2 {
      font-weight: 700;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 20px;
    }
    .v-btn {
      width: 100px;
      min-height: 40px;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
.buttonList {
  padding-left: 0;
  margin: 25px 0 40px;

  li {
    margin-left: 0;
    .v-btn {
      width: 180px;
      min-height: 50px;
    }
    &:first-of-type {
      .v-btn {
        background: #725ee3;
        color: #fff;
      }
    }
    &:last-of-type {
      margin-left: 10px;
      .v-btn {
        background: red;
        color: #fff;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    li {
      &:last-of-type {
        margin-left: 0;
      }
    }
  }
}
h2 {
  font-weight: 700;
  margin-top: 20px;
}
p {
  margin-bottom: 15px !important;
}
header {
  box-shadow: 0 3px 5px #efefef;
  padding: 5px 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -15px 0;
  img {
    width: 160px;
  }
}
.marginrow {
  margin: -15px -15px 0;
}
.gigs {
  padding-left: 0;
  width: 100%;
  margin-top: 10px;
  margin: 0;
  li {
    width: 100%;
    padding: 15px 20px;
    margin: 0;
    border-bottom: 1px solid #e3d4d4;
    a {
      color: #000;
    }
    p {
      margin-bottom: 5px !important;
    }
    .tab {
      border: 1px solid #cac6c6;
      display: inline-block;
      padding: 3px 10px;
      font-size: 12px;
      border-radius: 30px;
    }
  }
}
p {
  line-height: 25px;
  margin-top: 10px;
}

h3 {
  margin-top: 20px;
}

.listPoints {
  margin-top: 15px;
  padding-left: 0;
  li {
    margin: 0;
    padding-left: 15px;
    position: relative;
    line-height: 30px;
    &::after {
      content: "-";
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
.loadingBox {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 100px 0;
}
.headericons {
  margin-top: -6px;
  .v-icon {
    margin-left: 25px;
  }
}
</style>