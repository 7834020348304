<template>
  <section>
    <div class="filterButtons filterSearch">
      <div class="searchBox">
        <v-text-field
          outlined
          placeholder="Search Gigs"
          class=""
          v-model="searchKeyword"
          prepend-inner-icon="mdi-magnify"
          @keyup.enter="searchGigs"
        ></v-text-field>
      </div>
      <v-btn @click="categoryBottomSheet">
        <v-icon> mdi-filter-variant </v-icon>
      </v-btn>
    </div>
    <div v-if="loading" class="loadingBox">
      <v-progress-circular
        indeterminate
        size="100"
        color="blue"
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <ul class="gigs">
        <li v-for="(data, index) in gigs" :key="index + 'gig'">
          <router-link :to="`/gig/${data._id}?page=details`"
            ><div>
              <h4>{{ data.title }}</h4>
              <ul class="subList">
                <li>{{ data.level }}</li>
                <li>
                  Rs. {{ numberFormat(data.pricefrom) }}
                  <span v-if="data.priceto">
                    - Rs. {{ numberFormat(data.priceto) }}</span
                  >
                </li>
                <li>{{ data.gigApplied }} bid</li>
              </ul>
              <p>{{ data.description }}</p>

              <div class="userProfile">
                <div class="mt-1 d-flex align-center">
                  <v-rating
                    :value="data.rating"
                    dense
                    color="#FFBE5B"
                    readonly
                    background-color="#FFBE5B"
                  ></v-rating>
                  <strong class="ml-2">{{ data.rating }}.0</strong>
                </div>
                <div class="timeBox">
                  {{ getHourTime(data.posted) }}
                </div>
              </div>

              <div class="tabList">
                <span
                  class="tab"
                  v-for="(dat, index) in data.skills"
                  :key="index + 'skill'"
                  >{{ dat }}
                </span>
              </div>
            </div>
          </router-link>
        </li>
        <li v-if="gigs.length == 0">No Gigs found</li>
      </ul>
    </div>

    <div class="bottomSheet" :class="{ activeBottomCategory: bottomCategory }">
      <GigSearch
        @closeBottom="closeBottomSheet"
        :bottomSheet="bottomCategory"
      />
    </div>
  </section>
</template>

<script>
import moment from "moment";
import GigSearch from "@/components/gig/GigSearch";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Gig",
  data: () => ({
    gigs: [],
    search: false,
    bottomCategory: false,
    bottomSort: false,
    loading: true,
    loadingFilter: false,
    selectedCategory: undefined,
    selectedBusiness: undefined,
    searchKeyword: undefined,
  }),
  components: {
    GigSearch,
  },
  methods: {
    ...mapActions(["getGigs", "getCategories", "getBusinesses"]),
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },

    searchToggle() {
      this.search = !this.search;
    },
    sortBottomSheet() {
      this.bottomSort = true;
      this.bottomCategory = false;
    },
    categoryBottomSheet() {
      this.bottomCategory = true;
      this.bottomSort = false;
    },
    closeBottomSheet(data) {
      if (data) {
        this.gigs = data;
      }
      this.bottomCategory = false;
      this.bottomSort = false;
    },

    async searchGigs() {
      this.loading = true;
      await this.getGigs({
        limit: 10,
        skip: 1,
        searchKeyword: this.searchKeyword,
      });
      this.gigs = this.allGigs.results;
      this.loading = false;
    },

    getHourTime(date) {
      const now = moment(date);
      const expiration = moment(new Date());
      const diff = expiration.diff(now);
      const diffDuration = moment.duration(diff);
      if (diffDuration.days() != 0) {
        return diffDuration.days() + " day ago";
      }
      if (diffDuration.hours() != 0) {
        return diffDuration.hours() + " hour ago";
      }
      if (diffDuration.minutes != 0) {
        return diffDuration.minutes() + " minute ago";
      }
      return {
        days: diffDuration.days(),
        hours: diffDuration.hours(),
        minutes: diffDuration.minutes(),
      };
    },

    goBack() {
      this.$router.push("/gigback");
    },
  },
  computed: {
    ...mapGetters(["allGigs", "allCategories", "allBusinesses"]),
  },
  async created() {
    await this.getGigs({ limit: 10, skip: 1 });
    this.loading = false;
    this.gigs = this.allGigs.results;
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #6c45e8;
  text-transform: capitalize;
}
p {
  font-size: 12px;
}
.buttonList {
  padding-left: 0;
  margin: 25px 0 40px;

  li {
    margin-left: 0;
    .v-btn {
      width: 180px;
      min-height: 50px;
    }
    &:first-of-type {
      .v-btn {
        background: #725ee3;
        color: #fff;
      }
    }
    &:last-of-type {
      margin-left: 10px;
      .v-btn {
        background: red;
        color: #fff;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    li {
      &:last-of-type {
        margin-left: 0;
      }
    }
  }
}
h2 {
  font-weight: 700;
  margin-top: 20px;
}

header {
  box-shadow: 0 3px 5px #efefef;
  padding: 5px 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -15px 0;
  img {
    width: 160px;
  }
}
.price {
  font-size: 18px;
}
.gigs {
  padding-left: 0;
  width: 100%;
  margin-top: 15px;

  li {
    width: 100%;
    padding: 15px 20px;
    margin: 0;
    border-bottom: 1px solid #e3d4d4;
    a {
      color: #000;
    }
    p {
      margin-bottom: 5px !important;
    }

    &:last-of-type {
      margin-bottom: 30px;
    }
  }
}
.tabList {
  display: flex;
  overflow-x: scroll;
  margin-top: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
  .tab {
    padding: 5px 15px;
    font-size: 12px;
    border-radius: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: inline-block;
    background: #bcbcbc40;
    color: #444444;
    margin-right: 10px;
    text-transform: capitalize;
  }
}

.filterButtons {
  display: flex;
  justify-content: space-between;
  background: #f0f0f0;
  margin: 0 -15px;
  padding: 20px 25px 15px;
  .searchBox {
    width: 90%;
  }
  .v-btn {
    margin-left: 15px;
    width: 5%;
    min-height: 57px;
    background: transparent;
    box-shadow: none;
    border: 1px solid #bcb8b8;
    background: #fff;
    text-align: center;
    .v-icon {
      color: #bcbcbc;
    }
  }
}
.userProfile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #8c8c8c;
  img {
    border: 1px solid #efefef;
    border-radius: 50%;
    padding: 3px;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    object-fit: contain;
  }
  font-size: 16px;
  font-weight: 400;
  .profileInfo {
    display: flex;
    align-items: center;
  }
  .timeBox {
    font-size: 14px;
  }
}

.headericons {
  margin-top: -6px;
  .v-icon {
    margin-left: 25px;
  }
}
.bottomSheet {
  position: fixed;
  background: #fff;
  bottom: -150%;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 5px 5px #d6d1d1;
  z-index: 999;
  @media only screen and (max-width: 991px) {
    left: 0;
  }
}
.activeBottomCategory {
  top: 0;
  left: 0;
}
.activeBottomSort {
  bottom: -5px;
  transition: 0.3s all ease-in-out;
}
.bottomSheetOverlay {
  background: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
}
.loadingBox {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 100px 0;
}
.subList {
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  margin-bottom: -15px;
  li {
    color: #8c8c8c;
    width: auto;
    padding: 0;
    border: none;
    position: relative;
    padding-right: 20px;
    font-size: 14px;
    &:last-of-type {
      &::after {
        display: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      height: 20px;
      width: 1px;
      background: #dcdcdc;
      right: 10px;
    }
  }
}
</style>