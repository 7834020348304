<template>
  <section>
    <h2>Filter By :-</h2>
    <v-row>
      <v-col cols="12">
        <ul class="gigFilter">
          <li>
            <h3>Sort</h3>
            <div>
              <v-select
                outlined
                v-model="sort"
                :items="sorts"
                item-text="name"
                item-value="value"
                placeholder="Sort"
              ></v-select>
            </div>
          </li>
          <li>
            <h3>Category</h3>
            <div>
              <v-select
                outlined
                @change="getBusinessAct"
                v-model="category"
                :items="categories"
                item-text="name"
                item-value="name"
                placeholder="Category"
              ></v-select>
            </div>
          </li>
          <li>
            <h3>Company</h3>
            <div>
              <v-select
                outlined
                v-model="business"
                :items="businesses"
                item-text="businessName"
                item-value="userHandle"
                placeholder="Company"
              ></v-select>
            </div>
          </li>
          <li>
            <h3>Experience Level</h3>
            <div>
              <v-select
                outlined
                v-model="level"
                :items="levels"
                placeholder="Entry"
              ></v-select>
            </div>
          </li>
          <li>
            <h3>Budget</h3>
            <div class="d-flex">
              <v-text-field
                class="mr-2"
                v-model="offerAmountFrom"
                placeholder="From"
                outlined
              ></v-text-field>
              <v-text-field
                class="ml-2"
                v-model="offerAmountTo"
                placeholder="To"
                outlined
              ></v-text-field>
            </div>
          </li>
        </ul>

        <ul class="actionBtn" v-if="bottomSheet">
          <li><v-btn @click="closeBottomSheet">Clear</v-btn></li>
          <li><v-btn @click="filterGig" v-if="!loading">Apply Filter</v-btn> <v-btn v-if="loading" loading></v-btn></li>
        </ul>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "GigSearch",
  data: () => ({
    categories: [],
    businesses: [],
    business: undefined,
    category: undefined,
    sorts: [
      {
        name: "Latest",
        value: { createdAt: -1 },
      },
      {
        name: "Oldest",
        value: { offerAmountTo: -1 },
      },
      {
        name: "Highest Pay",
        value: { offerAmountFrom: -1 },
      },
      {
        name: "Lowest Pay",
        value: { offerAmountFrom: 1 },
      },
    ],
    sort: undefined,
    loadingFilter: false,
    levels: ["Entry", "Intermidiate", "Expert"],
    level: undefined,
    offerAmountTo: undefined,
    offerAmountFrom: undefined,
    gigs: undefined,
    loading: false,
  }),
  props: ["bottomSheet"],
  methods: {
    ...mapActions(["getGigs", "getCategories", "getBusinesses"]),
    closeBottomSheet() {
      if (this.gigs) {
        this.$emit("closeBottom", this.gigs);
      } else {
        this.$emit("closeBottom");
      }
    },
    async filterGig() {
      this.loading = true;
      let filter = {
        limit: 10,
        skip: 1,
      };
      if (this.sort != undefined) {
        filter.sort = this.sort;
      }
      if (this.category != undefined) {
        filter.category = this.category;
      }
      if (this.business != undefined) {
        filter.businessHandle = this.business;
      }
      if (this.level != undefined) {
        filter.level = this.level;
      }
      if (this.offerAmountFrom != undefined) {
        filter.offerAmountFrom = parseInt(this.offerAmountFrom);
      }
      if (this.offerAmountTo != undefined) {
        filter.offerAmountTo = parseInt(this.offerAmountTo);
      }
      await this.getGigs(filter);
      this.gigs = this.allGigs.results;
      this.loading = false;
      this.closeBottomSheet();
    },
    async getBusinessAct() {
      this.loadingFilter = true;
      await this.getBusinesses({ category: this.category });
      this.businesses = this.allBusinesses.results;
      this.loadingFilter = false;
    },
    async filterPost(data) {
      this.loading = true;
      await this.getGigs({
        limit: 10,
        skip: 1,
        businessHandle: data,
      });
      this.gigs = this.allGigs.results;
      this.selectedBusiness = data;
      this.loading = false;
      this.closeBottomSheet();
    },
  },
  computed: {
    ...mapGetters(["allGigs", "allCategories", "allBusinesses"]),
  },
  async created() {
    await this.getCategories();
    this.categories = this.allCategories.results;
    await this.getBusinesses();
    this.businesses = this.allBusinesses.results;
  },
};
</script>
<style lang="scss" scoped>
section {
  width: 100%;
}
h2 {
  border-bottom: 1px solid #dcdcdc;
  margin-top: 0;
  padding: 20px;
}
.scrollBox {
  height: 50vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 991px) {
    top: 0;
  }
  
}
.actionBtn {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  padding-left: 0;
  border-top: 1px solid #dcdcdc;
  padding: 20px 10px;
  background: #fff;
  li {
    width: 100%;
    .v-btn {
      width: 100%;
      min-height: 50px;
      box-shadow: none;
      text-transform: capitalize;
    }
    &:last-of-type {
      .v-btn {
        background: #5b3de8;
        color: #fff;
      }
    }
  }
}
.gigFilter {
  padding: 20px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  overflow-x: hidden;
  left: 0;
  &::-webkit-scrollbar {
    display: none;
  }
  li {
    h3 {
      margin-bottom: 15px;
      font-weight: 400;
      color: #8c8c8c;
    }
    width: 100%;
    margin-left: 0;
  }
}
</style>